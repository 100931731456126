import React, { useState, useEffect, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import work from '../img/menu_work.png';
import mojn from '../img/menu_me.png';
import per from '../img/hero.png';
import logowhite from '../img/logowhite.png';
import whitewheel from '../img/whitewheel.svg';
import whiteface from '../img/whiteface.png';
import home from '../img/home-pll.png';

const NavMenu = () => {
  const location = useLocation();
  const [scrolledPast, setScrolledPast] = useState(false);
  const [isNavVisible, setIsNavVisible] = useState(false);

  const handleScroll = useCallback(() => {
    const projectHeroRect = document.getElementById('project-hero')?.getBoundingClientRect();
    if (projectHeroRect) {
      const isScrolledPast = projectHeroRect.bottom < 0;
      setScrolledPast(isScrolledPast);
      console.log('Scrolled past:', isScrolledPast);
    }
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMenuShow = () => {
    setIsNavVisible(true);
    document.body.classList.add('no-scroll');
  };

  const handleMenuHide = () => {
    setIsNavVisible(false);
    document.body.classList.remove('no-scroll');

  };

  useEffect(() => {
    const debounceScroll = debounce(handleScroll, 0); // Adjust debounce time as needed
    window.addEventListener('scroll', debounceScroll);
    handleScroll(); // Check scroll position on mount

    const updateOverflow = () => {
      if (window.innerWidth <= 1080 && isNavVisible) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }
    };

    updateOverflow(); // Initial check

    window.addEventListener('resize', updateOverflow);

    return () => {
      window.removeEventListener('scroll', debounceScroll);
      window.removeEventListener('resize', updateOverflow);
      debounceScroll.cancel();
      document.body.style.overflow = 'auto'; // Ensure overflow is reset
    };
  }, [handleScroll, isNavVisible]);

  const getBackgroundColor = () => {
    if (scrolledPast) {
      return 'nav-black';
    }
    switch (location.pathname) {
      case '/Medable':
      case '/Omhu':
      case '/Jobindex':  
        return 'nav-white ';
      default:
        return 'nav-black ';
    }
  };

  return (
    <div>
      <nav className={getBackgroundColor()}>
        <Link to="/" className="cursor">
          <div className='left m-20'>
            <img 
              alt="Main logo" 
              src={per} 
              className="cursor logo" 
              onClick={scrollToTop} 
            />
            <img 
              alt="White logo" 
              src={logowhite} 
              className="cursor logowhite" 
              onClick={scrollToTop} 
            />
          </div>
        </Link>
        <div className='right'>
          <ul>
            <li>
              <Link to="/#work" className="work mx-20 cursor">
                <img src={work} className="mini menu-show" alt="Work icon" />
                <img src={whitewheel} className="mini menu-hide" alt="White wheel icon" />
                <div className="menu-item">
                  <div className="menu-title">Work</div>
                  <div className='myt-10 sub-menu'>
                    <ul>
                      <li>Casestudies</li>
                    </ul>
                  </div>
                </div>
              </Link>
            </li>
            <li>
              <Link to="/#mojn" className="work cursor">
                <img src={mojn} className="mini menu-show" alt="Mojn icon" />
                <img src={whiteface} className="mini menu-hide" alt="White face icon" />
                <div className="menu-item">
                  <div className="menu-title">Mojn</div>
                  <div className='myt-10 sub-menu'>
                    <ul>
                      <li>Experience</li>
                      <li>Education</li>
                      <li>Background</li>

                    </ul>
                  </div>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </nav>

      <div className="bottom-outer">
        <div className="burger-menu-see" onClick={handleMenuShow}>
        <svg width="100pt" height="100pt" version="1.1" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
 <path d="m12.5 25c0-3.4531 2.7969-6.25 6.25-6.25h62.5c3.4531 0 6.25 2.7969 6.25 6.25s-2.7969 6.25-6.25 6.25h-62.5c-3.4531 0-6.25-2.7969-6.25-6.25zm68.75 18.75h-62.5c-3.4531 0-6.25 2.7969-6.25 6.25s2.7969 6.25 6.25 6.25h62.5c3.4531 0 6.25-2.7969 6.25-6.25s-2.7969-6.25-6.25-6.25zm0 25h-62.5c-3.4531 0-6.25 2.7969-6.25 6.25s2.7969 6.25 6.25 6.25h62.5c3.4531 0 6.25-2.7969 6.25-6.25s-2.7969-6.25-6.25-6.25z"/>
</svg>

        </div>

        {isNavVisible && (
          <div className="nav-responsive">
            <div className="nav-responsive-left" onClick={handleMenuHide}>
              <Link to="/" className="work cursor" onClick={scrollToTop}>
                <img src={home} className="mini menu-show" alt="home" />
                <div className="menu-item">
                  <div className="menu-title">Home</div>
                </div>
              </Link>
            </div>
            <div className="nav-responsive-left">
              <Link to="/#work" className="work cursor" onClick={handleMenuHide}>
                <img src={work} className="mini menu-show" alt="work" />
                <div className="menu-item">
                  <div className="menu-title">Work</div>
                </div>
              </Link>
            </div>
            <div className="nav-responsive-right">
              <Link to="/#mojn" className="work cursor" onClick={handleMenuHide}>
                <img src={mojn} className="mini menu-show" alt="moin" />
                <div className="menu-item">
                  <div className="menu-title">Mojn</div>
                </div>
              </Link>
            </div>
            <div className="burger-menu-hidden" onClick={handleMenuHide}>Close</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavMenu;
