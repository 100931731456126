function blackbox() {
    return <div className="blackbox micro">
        <div className="red-dot"></div>
        <b>Thing are busy at the moment,
        </b>
        <a href="mailto:perloekkegaard@gmail.com" className="temp-link">but feel free to get in touch</a>
        or connect on
        <a
            href="https://www.linkedin.com/in/per-lyhne-l%C3%B8kkegaard-1206151a/"
            className="temp-link">Linkedin</a>
        for collabs.

    </div>;
}

export default blackbox;
